<template>
<b-card>
  <v-tabs background-color="white" color="accent-4" left>
      <v-tab>Kuponger</v-tab>
      <v-tab>Skapa ny</v-tab>
  
      <v-tab-item>
        <RedeemCodesTable 
          ref="redeemCodeTable"
        />
      </v-tab-item>
      <v-tab-item>

        <RedeemCodeEdit 
          ref="redeemCodeEdit"
        />
      </v-tab-item>

    </v-tabs>
</b-card>
  
</template>

<style lang="scss" scoped>

</style>

<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

import RedeemCodesTable from '@/view/pages/ml/redeem_code/RedeemCodesTable.vue';
import RedeemCodeEdit from '@/view/pages/ml/redeem_code/RedeemCodeEdit.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'redeem-codes',
  components: {
    RedeemCodesTable,
    RedeemCodeEdit
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['companies', 'currentCompanyId', 'currentPeriodId'])
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      redeem_codes: []
    };
  },
  watch: {
    
  },
  methods: {

    loadData() {
      axios 
        .get(`/redeem_code`)
        .then(res => {
          if (res.status === 200) {
            this.redeem_codes = res.data;
          }
        })
        .catch(err => {
          console.error(err);
        });
    },

    scrollToClassName(classname) {
      var that = this;
      setTimeout(function() {
        const el = that.$el.getElementsByClassName(classname)[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },
    
    
  }
};
</script>
